import { Component, Vue, Prop, Ref } from 'vue-property-decorator';

import vSelect from 'vue-select';

import CtaItem from '@/components/CtaItem/CtaItem.vue';
import { CtaItemType } from '@/components/CtaItem/CtaItem';
import SelectItem from '@/common/interfaces/SelectItem';
import Tick from '@/assets/icons/tick-alt.svg?inline';
import { fixDropdownAudioEye } from '@/utils/domUtils';
@Component({
  components: { CtaItem, vSelect, Tick },
})
export default class JournalFiltersComponent extends Vue {
  @Prop({ type: Array as () => CtaItemType[], default: [] })
  readonly CtaItems: CtaItemType[];

  @Prop()
  readonly DerSignupCta: CtaItemType;

  @Prop({ type: Array as () => SelectItem[], default: [] })
  readonly DropdownFilters: SelectItem[];

  @Prop({ type: String, default: 'All Topics' })
  readonly ActiveCategory: string;

  @Prop({ type: Object as () => SelectItem, default: () => { } })
  readonly VideoOnlyFilter: SelectItem;

  @Ref('journalDropdown') readonly journalDropdown: HTMLElement;

  selectedDestination: string = 'All Destinations';

  mounted() {
    var checkedOption = this.DropdownFilters.filter((f) => f.Checked)[0];
    if (checkedOption) {
      this.selectedDestination = checkedOption.Text;
    }

    fixDropdownAudioEye((this.journalDropdown as any).$el, this.DropdownFilters.slice(0, 2), false)
  }

  fixUlOnClose() {
    setTimeout(() => {
      fixDropdownAudioEye((this.journalDropdown as any).$el, this.DropdownFilters.slice(0, 2), false)
    }, 500);
  }

  setSelected(value: SelectItem) {
    window.location.href = value.Value.toString();
  }

  updateOuterFilter(value: boolean) {
    let url = window.location.href;
    if (!value) {
      let isNoreplyPresent = url.lastIndexOf("?Noredirect=");
      if(isNoreplyPresent !== -1){
        if(url[isNoreplyPresent-1] !== "/") {
          //expedition-stories?Noredirect=true
          window.location.href = url.substring(0, isNoreplyPresent) + "/video-only/" + url.substring(isNoreplyPresent);
        }
        else {
          ///expedition-stories/?Noredirect=true
          window.location.href = this.replaceLastSlash(url);
        }
      }
      else {
        if(url.lastIndexOf("/") === url.length-1){
          // /expedition-stories/
          window.location.href = this.replaceLastSlash(url);
        }
        else{
          // /expedition-stories
          window.location.href = url + "/video-only/"
        }
      }
    }
    else {
      if(url.indexOf("/video-only/") != -1){
        window.location.href = url.replace("/video-only/","/");
      }
      else if(url.indexOf("/video-only") != -1){
        window.location.href = url.replace("/video-only","/");
      }
    }
  }
  private replaceLastSlash(url : string)  {
    let index = url.lastIndexOf("/");
    return  url.substring(0, index) + "/video-only/" + url.substring(index+1);
  }
}

import { Component, Vue } from 'vue-property-decorator';
import { getComponentProps } from '@/utils/vueUtils';

import data, { cases } from './JournalFiltersModule.data';

import { JournalFiltersModuleViewModel } from './JournalFiltersModule.types';

import JournalFiltersComponent from '@/components/JournalFilters/JournalFilters.vue';

const JournalFiltersModuleProps = Vue.extend({
  props: getComponentProps<JournalFiltersModuleViewModel>(data[cases.SixFilters]),
});

@Component({
  components: { JournalFiltersComponent },
})
export default class JournalFiltersModule extends JournalFiltersModuleProps {
  get CtaItems() {
    return this.p.CtaItems;
  }
  get DropdownFilters() {
    return this.p.DropdownFilters;
  }
  get DerSignupCta () {
    return this.p.DerSignupCta;
  }
}

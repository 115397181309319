import { JournalFiltersModuleViewModel } from './JournalFiltersModule.types';

import { wrapTestData } from '@/utils/commonUtils';
import { CtaButtonStyle } from '@/common/interfaces/CtaLinkItem';

export const cases = {
  SixFilters: 'Six Filters',
};

const testData: { [key: string]: JournalFiltersModuleViewModel } = {
  [cases.SixFilters]: {
    CtaItems: [
      {
        Header: '',
        Button: {
          ButtonStyle: CtaButtonStyle.Secondary,
          Link: '#',
          Title: 'All Topics',
        },
      },
      {
        Header: '',
        Button: {
          ButtonStyle: CtaButtonStyle.Secondary,
          Link: '#',
          Title: 'Wildlife & Nature',
        },
      },
      {
        Header: '',
        Button: {
          ButtonStyle: CtaButtonStyle.Secondary,
          Link: '#',
          Title: 'Behind The Expedition',
        },
      },
      {
        Header: '',
        Button: {
          ButtonStyle: CtaButtonStyle.Secondary,
          Link: '#',
          Title: 'Culture',
        },
      },
      {
        Header: '',
        Button: {
          ButtonStyle: CtaButtonStyle.Secondary,
          Link: '#',
          Title: 'World View',
        },
      },
      {
        Header: '',
        Button: {
          ButtonStyle: CtaButtonStyle.Secondary,
          Link: '#',
          Title: 'Daily Expedition Reports',
        },
      },
    ],
    ActiveCategory: 'All Topics',
    DropdownFilters: [
      {
        Text: 'All Destinations',
        Value: '#',
        Checked: true,
      },
      {
        Text: 'Alaska',
        Value: '#',
        Checked: false,
      },
      {
        Text: 'Canada',
        Value: '#',
        Checked: false,
      },
    ],
    VideoOnlyFilter: {
      Text: 'Check',
      Checked: true,
      Value: 1
    },
    DerSignupCta: {
      Header: 'Our Reports are the next best thing to being there yourself! Have them sent to your email, and your friends and family',
      Button: {
        ButtonStyle: CtaButtonStyle.Primary,
        Link: '/der-signup-by-email/',
        Title: 'Get Reports By Email',
      },
    },
  },
};

export default wrapTestData<JournalFiltersModuleViewModel>(testData);
